






























































import Vue from "vue"
import {validateEmail} from "@/utils/validate";
import {AccountActions} from "@/utils/http";

declare interface DataType {
  accountActions: AccountActions;
  email: string;
  emailErrorMsg: string;
  showPassword: boolean;
  password: string;
  passwordErrorMsg: string;
  rules: Record<string, object>;
  loading: boolean;
  errorMsg: string;
}

export default Vue.extend({
  name: "Login",
  data(): DataType {
    return {
      accountActions: new AccountActions(this.$store.getters["profile/getToken"], this.$store.getters["profile/getExpire"]),
      email: "",
      emailErrorMsg: "",
      showPassword: false,
      password: "",
      passwordErrorMsg: "",
      rules: {
        required: (value: string) => !!value || "入力してください",
        minPassword: (value: string) => value.length >= 8 || "8文字以上のパスワードを入力してください",
        matchEmail: (value: string) => validateEmail(value) || "正しいメールアドレスを入力してください"
      },
      loading: false,
      errorMsg: ""
    }
  },
  methods: {
    handleLoginBtn() {
      if ((this.$refs.loginForm as Vue & { validate: () => boolean }).validate()) {
        this.loading = true
        this.accountActions.login(this.email, this.password,
            (response, token, expire) => {
              console.log(token, expire)
              this.$store.commit("profile/setLoginInfo", {key: token, expire: expire})
              this.loading = false
              this.$router.push("calendar")
            },
            response => {
              console.log(response.data)
              if (response.data.email) this.emailErrorMsg = response.data.email
              if (response.data.password) this.passwordErrorMsg = response.data.password
              if (response.data.non_field_errors) this.errorMsg = response.data.non_field_errors
              this.loading = false
            }
        )
      }
    },
  },
  computed: {
    isExpiredToken() {
      return this.$store.getters["profile/isExpiredToken"]
    }
  },
  mounted() {
    console.log(this.$store.getters["profile/getLoggedIn"])
    if (this.$store.getters["profile/getLoggedIn"]) this.$router.push("calendar")
  }
})
